import React from 'react';
import Particles from "react-tsparticles";
import './App.css';
import particlesOptions from "./particles.json";
import { FaTwitter, FaDiscord, FaMedium, FaChevronUp } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import Countdown from 'react-countdown';
import Image from 'react-graceful-image';
import ReactFullpage from '@fullpage/react-fullpage';
import "animate.css/animate.min.css";
import Fade from 'react-reveal/Fade';
import { useMediaQuery } from 'react-responsive';
import Coverflow from 'react-coverflow';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col} from 'react-bootstrap';

const Socials = ({iconSize}) => {
    return (<span className="socials">
        <a href="https://twitter.com/nftmoonbots"><div className="social-link"><FaTwitter color="white" size={iconSize}/></div></a>
        <a href="https://medium.com/@moonbotsnft/"><div className="social-link"><FaMedium color="white" size={iconSize}/></div></a>
        <a href="https://discord.gg/rYBWe7JjsR"><div className="social-link"><FaDiscord color="white" size={iconSize}/></div></a>
    </span>)
}

export const SmallerSocials = () => {
    return(<div className="bottom-right">
        <Socials iconSize="20px"/>
    </div>)
}
//<sup className="red">•</sup>
const NavBar = ({fullpageApi, flickerIn, isMobile}) => {
    console.log(flickerIn);
    const [showMobileMenu, setShowMobileMenu] = React.useState(false);
    if (!isMobile) {
        return (<header className="navheader flicker-in">
        <ul class="nav">
            <li><a onClick={() => fullpageApi.moveTo(1, 0)} className="noflicker"><Image width="100px" src='/images/moonbotslogo.png' noLazyLoad placeholderColor="#000000"/></a></li>
            <li><a onClick={() => fullpageApi.moveTo(2, 0)} className="noflicker">Story</a></li>
            <li><a onClick={() => fullpageApi.moveTo(3, 0)} className="noflicker">Bots</a></li>
            <li><a onClick={() => fullpageApi.moveTo(4, 0)} className="noflicker">FAQ</a></li>
            <li><a onClick={() => fullpageApi.moveTo(5, 0)} className="noflicker">Transmissions</a></li>
            <li><a onClick={() => fullpageApi.moveTo(6, 0)} className="noflicker">Roadmap</a></li>
            <li><a href="https://moonbeans.io/#/collections/moonbots" target="_blank" className="noflicker">Marketplace</a></li>
            <li><a href="https://snapshot.org/#/moonbots.eth" className="noflicker">DAO</a></li>
            <li><a href="/documents/parts.pdf" className="noflicker">PARTS<sup className="red">•</sup></a></li>
            <li><a href="#scrapyard" className="noflicker">Scrapyard<sup className="red">soon</sup></a></li>
        </ul>
    </header>)
    } else {
        return (<header className="navheader flicker-in">
        <ul className="nav mobile-nav ">
            <li><a onClick={() => {setShowMobileMenu(false); fullpageApi.moveTo(1, 0);}} className="noflicker"><Image width="100px" src='/images/moonbotslogo.png' noLazyLoad placeholderColor="#000000"/></a></li>
            <li><a onClick={() => setShowMobileMenu(!showMobileMenu)} className="noflicker"><GiHamburgerMenu/></a></li>
            {showMobileMenu && <div className="mobile-menu">
                    <li><a onClick={() => {setShowMobileMenu(false); fullpageApi.moveTo(2, 0);}} className="noflicker">Story</a></li>
                    <li><a onClick={() => {setShowMobileMenu(false); fullpageApi.moveTo(3, 0);}} className="noflicker">Bots</a></li>
                    <li><a onClick={() => {setShowMobileMenu(false); fullpageApi.moveTo(4, 0);}} className="noflicker">FAQ</a></li>
                    <li><a onClick={() => {setShowMobileMenu(false); fullpageApi.moveTo(5, 0);}} className="noflicker">Transmissions</a></li>
                    <li><a onClick={() => {setShowMobileMenu(false); fullpageApi.moveTo(6, 0);}} className="noflicker">Roadmap</a></li>
                    <li><a href="https://moonbeans.io/#/collections/moonbots" target="_blank" className="noflicker">Marketplace</a></li>
                    <li><a href="https://snapshot.org/#/moonbots.eth" className="noflicker">DAO</a></li>
                    <li><a href="/documents/parts.pdf" className="noflicker">PARTS<sup className="red">•</sup></a></li>
                    <li><a href="#scrapyard" className="noflicker">Scrapyard<sup className="red">soon</sup></a></li>
                </div>}
        </ul>
    </header>)
    }

}


function App() {
    const stopTime = '2021-12-12T23:00:00';
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
    const [destination, setDestination] = React.useState(0);

    function adjustedCountdownDate() {
        var targetTime = new Date(stopTime);
        targetTime.setMinutes(targetTime.getMinutes() - targetTime.getTimezoneOffset());
        return targetTime > Date.now() ? targetTime : false;
      }

      function onLeave(origin, destination, direction) {
        console.log("Leaving section " + origin.index);
      }
      function afterLoad(origin, destination, direction) {
        console.log("After load: " + destination.index);
        if (destination?.index !== undefined) {
            setDestination(destination.index);
        }
      }

    return (<>
            {!isMobile && <Image src="/images/mark_white.png" className="moon-bg" placeholderColor="#000000"/>}
            <ReactFullpage
                licenseKey = {'bW9vbmJvdHMuYXJ0X0lPV1kyRnlaSE09OTk4'}
                scrollingSpeed = {1000} /* Options here */
                onLeave={onLeave.bind(this)}
                afterLoad={afterLoad.bind(this)}
                render={({ state, fullpageApi }) => {
                    return (   
                    <>
                        <ReactFullpage.Wrapper>
                        <link
                            rel="stylesheet"
                            href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
                            integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
                            crossorigin="anonymous"
                            />
                            <div className="App">
                                <div className="section section1" id="section1">
                                    <NavBar fullpageApi={fullpageApi} flickerIn={destination} isMobile={isMobile}/>
                                    <Particles options={particlesOptions}/>
                                    <header className="App-header">
                                        {adjustedCountdownDate() && <div className="countdown-text">
                                            <Countdown date={adjustedCountdownDate()}>
                                                Beep Boop
                                            </Countdown>
                                        </div>}
                                        <div className="logo-wrapper"><Image src="/images/1.png" className="App-logo" alt="logo" placeholderColor="#000000"/></div>
                                        {adjustedCountdownDate() ? 
                                            <div className="coming-soon-text">Engineers are preparing...</div> : <>
                                            <div className="coming-soon-text">Assemble your army...<a className="flicker" href="https://moonbeans.io/#/collections/moonbots">Trade</a></div>
                                            <div className="coming-soon-text">The empire approaches...<a className="flicker flicker-red" href="#/game">Fight</a></div></>}

                                        <Socials iconSize={`40px`}/>
                                        <div className="bottom-right">
                                        <div class="mouse">
                                            <span></span>
                                            </div>
                                        </div>
                                    </header>
                                </div>
                                <div className="section section2" id="section2">
                                    <div className="bottom-left">
                                        <span className="socials">
                                            <a onClick={() => fullpageApi.moveTo(1, 0)}><div className="social-link"><FaChevronUp color="white" size={"20px"}/></div></a>
                                        </span>
                                    </div>
                                        <NavBar fullpageApi={fullpageApi} isMobile={isMobile}/>
                                        <Particles options={particlesOptions}/>
                                        <header className="App-header">
                                            <div className="section-header">STORY</div>
                                            <Fade up distance="10vh">
                                                <div className="section-body">
                                                <b>Beep boop.</b><br/><br/>
                                                Somewhere on a sunburnt planet, where oxygen has lost to carbon, and water droplet condensation in underground caverns 
                                                is all that remains of our organic building blocks.<br/><br/>
                                                On the surface lies rotted metal, rusted carcasses of aero craft & automobiles, eroding skyscrapers serving only as 
                                                lightning rods to storms with no rain, only thunder. Great winds carry garbage and ash across sky’s heavy with toxic
                                                gas, where garbage dumps are the strongest reminders of humanities remains.<br/><br/>
                                                At one of this world’s many ends, lies a cliff where the residue and derelict structures of a long dead city state
                                                collect. Soda pop cans, plastic water containers, overturned furniture, vehicles, and not a shred of organic 
                                                material remains. Thousand year old rat carcasses serve as the only reminder of what possibly could have remained 
                                                among the wreckage in the end times.<br/><br/>
                                                A skyscraper collapses. The earth shakes apart as massive clouds of dust and rust rumble away in a mushroom cloud 
                                                of iron & steel. A tree has fallen in the forest, and no one can confirm the sound. New passageways form as air 
                                                and soot start to blow in new directions where they never have before.<br/><br/>
                                                <a className="flicker" href="https://medium.com/@moonbotsnft/moonbots-episode-1-beep-boop-6e53c8f3a3d5">Read More...</a>
                                                </div>
                                            </Fade>
                                            <SmallerSocials/>
                                        </header>
                                </div>
                                <div className="section section3" id="section3">
                                    <div className="bottom-left">
                                        <span className="socials">
                                            <a onClick={() => fullpageApi.moveTo(1, 0)}><div className="social-link"><FaChevronUp color="white" size={"20px"}/></div></a>
                                        </span>
                                    </div>
                                        <NavBar fullpageApi={fullpageApi} isMobile={isMobile}/>
                                        <Particles options={particlesOptions}/>
                                        <header className="App-header">
                                            <Container>
                                                <Row>
                                                    <Col xs lg="6">
                                                    <div className="section-header">BOTS</div>
                                                    <div className="botfont"><b>Built from scraps.</b><br/><br/></div>
                                                    <Fade up distance="10vh">
                                                        <div className="section-body larger">
                                                            
                                                            <Coverflow width={isMobile ? "400" : "800"} height={isMobile ? "200" : "500"}
                                                                displayQuantityOfSide={1}
                                                                navigation={false}
                                                                enableScroll={true}
                                                                clickable={true}
                                                                active={5}
                                                                infiniteScroll={true}
                                                            >
                                                                <Image src={`images/bots/1.jpg`} noLazyLoad/>
                                                                <Image src={`images/bots/2.jpg`} noLazyLoad/>
                                                                <Image src={`images/bots/3.jpg`} noLazyLoad/>
                                                                <Image src={`images/bots/4.jpg`} noLazyLoad/>
                                                                <Image src={`images/bots/5.jpg`} noLazyLoad/>
                                                                <Image src={`images/bots/6.jpg`} noLazyLoad/>
                                                                <Image src={`images/bots/7.jpg`} noLazyLoad/>
                                                                <Image src={`images/bots/8.jpg`} noLazyLoad/>
                                                                <Image src={`images/bots/9.jpg`} noLazyLoad/>
                                                                <Image src={`images/bots/10.jpg`} noLazyLoad/>

                                                            </Coverflow>
                                                        </div>
                                                    </Fade>
                                                    </Col>
                                                    <Col xs lg="6">
                                                    <div className="section-header">Guns</div>
                                                    <div className="botfont"><b>Fight for the empire.</b><br/><br/></div>
                                                    <Fade up distance="10vh">
                                                        <div className="section-body">
                                                            
                                                            <Coverflow width={isMobile ? "400" : "800"} height={isMobile ? "200" : "500"}
                                                                displayQuantityOfSide={1}
                                                                navigation={false}
                                                                enableScroll={true}
                                                                clickable={true}
                                                                active={5}
                                                                infiniteScroll={true}
                                                            >
                                                                <Image src={`images/guns/1.png`} noLazyLoad/>
                                                                <Image src={`images/guns/2.png`} noLazyLoad/>
                                                                <Image src={`images/guns/3.png`} noLazyLoad/>
                                                                <Image src={`images/guns/4.png`} noLazyLoad/>
                                                                <Image src={`images/guns/5.png`} noLazyLoad/>
                                                                <Image src={`images/guns/6.png`} noLazyLoad/>
                                                                <Image src={`images/guns/7.png`} noLazyLoad/>
                                                                <Image src={`images/guns/8.png`} noLazyLoad/>
                                                                <Image src={`images/guns/9.png`} noLazyLoad/>
                                                                <Image src={`images/guns/10.png`} noLazyLoad/>
                                                                <Image src={`images/guns/11.png`} noLazyLoad/>
                                                            </Coverflow>
                                                        </div>
                                                    </Fade>
                                                    </Col>
                                                </Row>
                                            </Container>
                                            <SmallerSocials/>
                                        </header>
                                </div>
                                <div className="section section4" id="section4">
                                    <div className="bottom-left">
                                        <span className="socials">
                                            <a onClick={() => fullpageApi.moveTo(1, 0)}><div className="social-link"><FaChevronUp color="white" size={"20px"}/></div></a>
                                        </span>
                                    </div>
                                        <NavBar fullpageApi={fullpageApi} isMobile={isMobile}/>
                                        <Particles options={particlesOptions}/>
                                        <header className="App-header">
                                            <div className="section-header">FAQ / Overview</div>
                                            <Fade up distance="10vh">
                                                <div className="section-body smaller-text">
                                                <b>An NFT-DAO Empire from the Dark Side of Moonriver: Expansive equip-economy of art with built in rewards.</b><br/><br/>
                                                        MoonBots are the first base part of our Empire.<br/><br/>
                                                        174 total bots minted - randomly generated and unique art pieces<br/><br/>
                                                    <Container>
                                                        <Row>
                                                            <Col xs lg="4">
                                                                <b>Sale: </b>
                                                                12/11 5pm EST - 0.69 MOVR - First come first serve whitelist sale for 24 hr period. No limit on mints per Scrap Picker to build their army<br/>
                                                                12/12 5pm EST- Public sale batches begin from 0.75 MOVR to 2 MOVR.<br/>
                                                                MoonBot holders will additionally be rewarded with whitelist to future token launch.
                                                                <br/><br/>
                                                            </Col>
                                                            <Col xs lg="4">
                                                                <b>New Empire Dao: </b>
                                                                DAO governed by NFT ownership. Choose a faction to represent, or stay sigma.
                                                                Holders decide alongside their Bots.  You have the power to influence change by proposing action and voting on the future of the empire. You decide the way forward. No stone unturned.
                                                                <br/><br/>
                                                            </Col>
                                                            <Col xs lg="4">

                                                                <b>Universal Income: </b>
                                                                MoonBots holders directly benefit from the success of the project. Engineers are using our sharing minting and trading fees to secure initial and on going rewards to fund the treasury that holders can control in DAO.
                                                                <br/>
                                                                Total 7% fee broken down
                                                                3% to Beans for hosting
                                                                2% to MoonBots Engineering Fund 2% to MoonBots Holders/Community Treasury 
                                                        <       br/><br/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs lg="6">
                                                                <b>Equip Economy of the Empire: </b>
                                                                Bots are our first base parts and Guns are the first limited run accessory. 
                                                                Our large parts catalog will be made accessible through Build-a-Bot. 
                                                                NFT’s can be separately admired and traded but also collected and made equip-able.
                                                                <br/><br/>
                                                            </Col>
                                                            <Col xs lg="6">
                                                                <b>Wage War with your Bot: </b>
                                                                Equip your army and show no mercy. 
                                                                Pink Slip - rng bot battles, risk your NFT.
                                                                MoonBots mini games that factor equipped guns and total empire assets as point of contention and strategy in-game.
                                                                <br/><br/>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                    <b>This is only the beginning of the Empire’s reign, join the ranks and earn your influence.</b>
                                                </div>
                                            </Fade>
                                            <SmallerSocials/>
                                        </header>
                                </div>
                                <div className="section section5" id="section5">
                                        <div className="bottom-left">
                                            <span className="socials">
                                                <a onClick={() => fullpageApi.moveTo(1, 0)}><div className="social-link"><FaChevronUp color="white" size={"20px"}/></div></a>
                                            </span>
                                        </div>
                                        <NavBar fullpageApi={fullpageApi} isMobile={isMobile}/>
                                        <Particles options={particlesOptions}/>
                                        <header className="App-header">
                                            <div className="section-header">Important Transmissions</div>
                                            <Fade up distance="10vh">
                                                <div className="section-body">
                                                    <div className="video-row">
                                                            <video className="promo-video" controls>
                                                                <source src="/videos/pr_1.mp4" type="video/mp4"/>
                                                                Transmission failed, transmitter upgrade required...
                                                            </video>
                                                            <video className="promo-video" controls>
                                                                <source src="/videos/mbprom.mp4" type="video/mp4"/>
                                                                Transmission failed, transmitter upgrade required...
                                                            </video>
                                                    </div>
                                                </div>
                                            </Fade>
                                            <SmallerSocials/>
                                        </header>
                                </div>
                                <div className="section section6" id="section6">
                                        <div className="bottom-left">
                                            <span className="socials">
                                                <a onClick={() => fullpageApi.moveTo(1, 0)}><div className="social-link"><FaChevronUp color="white" size={"20px"}/></div></a>
                                            </span>
                                        </div>
                                        <NavBar fullpageApi={fullpageApi} isMobile={isMobile}/>
                                        <Particles options={particlesOptions}/>
                                        <header className="App-header">
                                            <div className="section-header">ROADMAP</div>
                                            <Fade up distance="10vh">
                                                <div className="section-body-text-only">
                                                    <b>Navigating Star Charts.</b><br/><br/>
                                                    {!isMobile && <Image src="/images/roadmap.png" className="roadmap-image" alt="logo" placeholderColor="#000000" noLazyLoad={true}/>}
                                                    {isMobile && <Image src="/images/roadmap2.png" className="roadmap-image-mobile" alt="logo" placeholderColor="#000000" noLazyLoad={true}/>}
                                                </div>
                                            </Fade>
                                            <SmallerSocials/>
                                        </header>
                                </div>
                            </div>
                    </ReactFullpage.Wrapper>
                </>);
                }}
            />
    </>);
}

export default App;
