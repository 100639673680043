import React from 'react';
import {HashRouter as Router, Routes, Route} from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Game from './Game';
import * as serviceWorker from './serviceWorker';
import { DAppProvider } from "@usedapp/core";

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider>
      <Router>
        <Routes>
            <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<App/>}/>
            <Route path={`${process.env.PUBLIC_URL + "/game"}`} element={<Game/>}/>
        </Routes>
      </Router>
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
